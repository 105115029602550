import React from "react";
import styled from "styled-components";

// Utils
import { responsive, media } from "../utils/style";

// Components
import Img from "gatsby-image/withIEPolyfill";

// Styled Elements
const Wrapper = styled.div`
  background-color: ${p => p.backgroundColor};
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 52px;

  ${responsive.md`
    margin-top: 64px;
  `};

  width: calc(100vw - 16px);
  height: calc(100vw - 16px);

  &.short {
    height: calc((100vw - 16px) * 0.72368421052);
  }

  &.height-fit {
    height: fit-content;
  }

  &.full-width-mobile {
    ${media.mobile`
      width: 100%;
    `}
  }

  padding-left: 8px;
  padding-right: 8px;

  ${responsive.sm`
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 0.52778);
    padding-left: 0px;
    padding-right: 0px;

    &.short {
      height: calc((100vw - 48px) * 0.52778);
    }

    &.height-fit {
      height: fit-contet;
    }

  `}

  ${responsive.md`
    height: calc((100vw - 48px) * 0.3883196721);

    &.short {
      height: calc((100vw - 48px) * 0.3883196721);
    }

    &.height-fit {
      height: fit-content;
    }

  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    height: calc((100vw - 80px) * 0.3888024883);
    max-width: 1920px;
    max-height: calc(1920px * 0.3888024883);

    &.short {
      height: calc((100vw - 80px) * 0.3888024883);
    }

    &.height-fit {
      height: fit-content;
      max-height: fit-content;
    }

  `}

  > .container {
    height: 100%;

    > .row {
      height: 100%;

      > .col-12 {
        height: 100%;
      }
    }
  }
`;

const Column = styled.div`
  position: relative;
`;

const ContentArea = styled.div`
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
`;

export default class PageHero extends React.Component {
  render() {
    const {
      id = "page-hero",
      backgroundColor = "#ffd600",
      children,
      className,
      columnStyling = "col-12",
      imageMobile,
      imageDesktop,
      imageObjectPosition = "50% 50%",
    } = this.props;

    const defaultStyle = {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: "0",
      userSelect: "none",
      userDrag: "none",
      pointerEvents: "none",
      touchCallout: "none",
    };

    return (
      <Wrapper id={id} className={className} backgroundColor={backgroundColor}>
        <div className="container">
          <div className="row">
            <Column className={columnStyling}>
              <ContentArea id={`${id}_content`}>{children}</ContentArea>
            </Column>
          </div>
        </div>
        <div>
          {imageMobile && (
            <Img
              id={`${id}_mobile-image`}
              className="d-sm-none"
              fluid={imageMobile.fluid}
              loading="eager"
              alt={imageMobile.description}
              style={defaultStyle}
              objectFit="cover"
              objectPosition={imageObjectPosition}
            />
          )}
          {imageDesktop && (
            <Img
              id={`${id}_desktop-image`}
              className="d-none d-sm-block"
              fluid={imageDesktop.fluid}
              loading="eager"
              alt={imageDesktop.description}
              style={defaultStyle}
            />
          )}
        </div>
      </Wrapper>
    );
  }
}
