import Marked from "marked";
import intl from "../services/intl";

export function localizeMarkdownLinks(data) {
  return data.replace(
    /\[([^\]]*)\]\(([^)]*)\)/g,
    (_, g1, g2) => `[${g1}](${intl.localizePath(g2)})`,
  );
}

export function localizeHtmlLinks(data) {
  return data.replace(
    /href="(.*?)"/g,
    (_, g1) => `href="${intl.localizePath(g1)}"`,
  );
}

export function parseMarkdown(content) {
  const localized = localizeMarkdownLinks(content);
  return Marked.parseInline(localized);
}

export function processHtml(content) {
  return localizeHtmlLinks(content);
}
