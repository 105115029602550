import React from "react";
import styled from "styled-components";

const FlexWrapper = styled.div.attrs({
  className: "flex-wrapper",
})`
  display: flex;
  align-items: center;
`;

const FlexContent = styled.div`
  width: 100%;
`;

const VerticallyCentered = props => {
  return (
    <FlexWrapper>
      <FlexContent>{props.children}</FlexContent>
    </FlexWrapper>
  );
};

export default VerticallyCentered;
